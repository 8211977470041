.app__footer {
	flex: 1;
	width: 100%;
	flex-direction: column;
}

.app__footer-container {
	width: 80%;
	margin-top: 3rem;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media screen and (max-width: 900px) {
		width: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.app__footer-img {
	flex: 0 0 auto;
	max-width: 50%;
	display: flex;
	justify-content: flex-end;
	padding-right: 5rem;

	img {
		width: 100%;
		height: auto;
	}

	@media screen and (max-width: 900px) {
		padding-right: 0;
	}
}

.app__footer-cards {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin-right: 5rem;

	.app__footer-card {
		min-width: 290px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		margin: 1rem 0;
		padding: 1rem;
		border-radius: 10px;
		cursor: pointer;

		transition: all 0.3s ease-in-out;

		img {
			width: 40px;
			height: 40px;
			margin: 0 0.7rem;
		}

		p {
			font-weight: 500;
		}
		a {
			text-decoration: none;
			font-weight: 500;
		}
		&:hover {
			box-shadow: 0 0 25px #fef4f5;
		}

		@media screen and (max-width: 450px) {
			width: 100%;
			justify-content: center;
		}
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		margin-left: 0;
		justify-content: center;
		align-items: center;
		margin-right: 0;
	}
}

#email {
	background-color: #fdf8e8;
}

#phone {
	background-color: #c2fac6a8;
}

#linkedin {
	background-color: #afe8eea8;
}

#github {
	background-color: #b9bed8a8;
}
